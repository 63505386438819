import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Container from 'react-bootstrap/Container'

import BlogPosts from '../components/sections/blogposts'

const BlogPage = ({ data: { site } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Blog — {site.siteMetadata.title}</title>
        <meta
          name="description"
          content={'Blog page of Andreas. ' + site.siteMetadata.description}
        />
      </Helmet>
      <Container className="hero-header">
        <h1>
          <span id="greeting">Welcome to my Blog!</span>
        </h1>
        <p>A Blog about Internships, Work & Technology😎</p>
      </Container>
      <BlogPosts />
    </Layout>
  )
}
export default BlogPage
export const pageQuery = graphql`
  query BlogPageQuery {
    site {
      siteMetadata {
        title
        description
        home {
          name
          role
          location
        }
      }
    }
  }
`
